import {
    getElement,
    addClassName,
    eventOn,
    hasClassName,
    getElementsList
} from './DomUtils';

/**
 * @class ChartComponent
 * @public
 */
export default class ChartComponent {

    private chart: Element;
    private points: NodeListOf<Element>;

    /**
     * @constructor
     * @param {Element} chartElement
     * @public
     */
    public constructor(chartElement: Element) {
        this.chart = chartElement;
        this.points = getElementsList('[data-plot^="circle-"]', chartElement);
        this.bindEventHandlers();
    }

    /**
     * @method animate
     * @private
     */
    private animate(): void {
        let offset: ClientRect = this.chart.getBoundingClientRect();
        if (!hasClassName('plot--animate', this.chart) && offset.bottom < window.innerHeight) {
            addClassName('plot--animate', this.chart);
        }
    }

    /**
     * @method setLabelOpacity
     * @param {Element} point
     * @param {string} value
     * @private
     */
    private setLabelOpacity(point: Element, value: string): void {
        let label: string = point.getAttribute("data-plot").replace("circle-", "label-");
        let labelElement: HTMLElement = <HTMLElement>getElement('[data-plot="' + label + '"]', this.chart);
        if (labelElement) {
            labelElement.style.opacity = value;
        }
    }

    /**
     * Bind all event handlers
     * @method bindEventHandlers
     * @private
     */
    private bindEventHandlers(): void {
        window.addEventListener('scroll', () => this.animate());
        for (let i: number = 0; i < this.points.length; i++) {
            eventOn(this.points[i], 'mouseenter', () => this.setLabelOpacity(this.points[i], '1'));
            eventOn(this.points[i], 'mouseleave', () => this.setLabelOpacity(this.points[i], '0'));
        }
    }

}
