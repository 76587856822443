export const document: Document = window.document;
export const documentElement: Element = document.documentElement;
export const bodyElement: Element = document.body;
export let savedWindowPosition: number;

/**
 * @function getElement
 * @param {string} selector
 * @param {Element|Document} parent
 * @return {Element|null}
 */
export function getElement(selector: string, parent: Element|Document = document): Element {
    return parent.querySelector(selector);
}

/**
 * @function getElementsList
 * @param selector
 * @param {Element|Document} parent
 * @return {NodeListOf<Element>}
 */
export function getElementsList(selector: string, parent: Element|Document = document): NodeListOf<Element> {
    return parent.querySelectorAll(selector);
}

/**
 * @function addClassName
 * @param {string} className
 * @param {Element} element
 */
export function addClassName(className: string, element: Element): void {
    element.classList.add(className);
}

/**
 * @function removeClassName
 * @param {string} className
 * @param {Element} element
 */
export function removeClassName(className: string, element: Element): void {
    element.classList.remove(className);
}

/**
 * @function toggleClassName
 * @param {string} className
 * @param {Element} element
 */
export function toggleClassName(className: string, element: Element): void {
    element.classList.toggle(className);
}

/**
 * @function hasClassName
 * @param {string} className
 * @param {Element} element
 * @return {boolean}
 */
export function hasClassName(className: string, element: Element): boolean {
    return element.classList.contains(className);
}

/**
 * @function eventOn
 * @param {EventTarget} target - may be Element, Document, Window, or any object such as XMLHttpRequest
 * @param {string} type - the event type to listen out for.
 * @param {EventListener} handler - event handler
 * @param {boolean} useCapture - events will be dispatched to the registered listener,
 * before being dispatched to any EventTarget beneath it in the DOM tree.
 */
export function eventOn(target: EventTarget, type: string, handler: EventListener, useCapture: boolean = false): void {
    target.addEventListener(type, handler, useCapture);
}

/**
 * @function eventOff
 * @param {EventTarget} target - may be Element, Document, Window, or any object such as XMLHttpRequest
 * @param {string} type - the event type to listen out for.
 * @param {EventListener} handler - event handler
 * @param {boolean} useCapture - events will be dispatched to the registered listener,
 * before being dispatched to any EventTarget beneath it in the DOM tree.
 */
export function eventOff(target: EventTarget, type: string, handler: EventListener, useCapture: boolean = false): void {
    target.removeEventListener(type, handler, useCapture);
}

/**
 * @function getWindowScroll
 * @return {number}
 */
export function getWindowScroll(): number {
    let offset: number = window.pageYOffset || documentElement.scrollTop;
    let top: number = documentElement.clientTop || 0;
    return offset - top;
}

/**
 * @function windowScrollTo
 * @param {number} value
 */
export function windowScrollTo(value: number): void {
    window.scrollTo(0, value);
}

/**
 * @function updateSavedWindowPosition
 */
export function updateSavedWindowPosition(): void {
    savedWindowPosition = getWindowScroll();
}

/**
 * @function getWindowWidth
 * @return {number}
 */
export function getWindowWidth(): number {
    return Math.max(documentElement.clientWidth, window.innerWidth || 0);
}

/**
 * @function getWindowHeight
 * @return {number}
 */
export function getWindowHeight(): number {
    return window.innerHeight || documentElement.clientHeight || bodyElement.clientHeight;
}

/**
 * @function overflow
 */
export function overflow(): void {
    if (hasClassName('body--overflow', bodyElement)) {
        removeClassName('body--overflow', bodyElement);
        if (getWindowWidth() < 1024) {
            window.scrollTo(0, savedWindowPosition);
        }
        return;
    }
    updateSavedWindowPosition();
    addClassName('body--overflow', bodyElement);
}

/**
 * @function offset
 * @param {Element} target
 * @return {{top: number, left: number}}
 */
export function offset(target: Element): {top: number, left: number} {
    let rect: ClientRect = target.getBoundingClientRect();
    return {
        top: rect.top + bodyElement.scrollTop,
        left: rect.left + bodyElement.scrollLeft
    };
}

/**
 * @function closest
 * @param {Element} element
 * @param {Function} predicate
 * @return {Element}
 */
export function closest(element: Element, predicate: Function): Element {
    return predicate(element) ? element : (element && closest(element.parentElement, predicate));
}
