import Validator from './Validator';
import {
    document,
    getElementsList,
    getElement,
    addClassName,
    removeClassName,
    eventOn,
    closest, hasClassName
} from './DomUtils';

/**
 * @class FormComponent
 * @public
 */
export default class FormComponent {

    private form: Element;
    private validatorsMap: Map<string, Validator>;
    protected hasError: boolean;
    protected fieldPrefix: string;
    protected url: string;

    /**
     * @constructor
     * @param {Element} parent - parent element
     * @public
     */
    public constructor(parent: Element) {
        this.form = getElement('form', parent);
        this.validatorsMap = new Map<string, Validator>();
        this.validatorsMap.set('.input__field--email', new Validator('email'));
        this.validatorsMap.set('.input__field--text', new Validator('required'));
        this.validatorsMap.set('.upload__drop-file', new Validator('fileMaxSize'));
        this.validatorsMap.set('.checkbox', new Validator('checked'));
        this.hasError = false;
        this.fieldPrefix = '';
        this.url = '';
        this.bindFormEventHandlers();
    }

    /**
     * Form submit handler
     * @method submit
     * @protected
     */
    protected submit(): void {
        this.checkErrors();
        if (this.hasError) {
            return;
        }
        let xhr: XMLHttpRequest = new XMLHttpRequest();
        xhr.open('POST', this.url, true);
        
        xhr.send(this.getFormData());
        Promise.all([
            new Promise((resolve: Function) => setTimeout(resolve, 1400)),
            new Promise((resolve: Function) => eventOn(xhr, 'load', (e: Event) => resolve(e)))
        ]).then((results: any) => this.submitted(<Event>results[1]));
    }

    /**
     * Form submitted handler
     * @method submitted
     * @param {Event} e
     * @protected
     */
    protected submitted(e: Event): void {}

    /**
     * Get values of all form fields
     * @method getFormData
     * @return {Object}
     * @private
     */
    private getFormData(): FormData {
        let formData: FormData = new FormData();
        let fieldsList: NodeListOf<HTMLInputElement>;
        let filesFieldsList: NodeListOf<HTMLInputElement>;
        fieldsList = <NodeListOf<HTMLInputElement>>getElementsList('.input__field', this.form);
        for (let i: number = 0; i < fieldsList.length; i++) {
            formData.append(this.fieldPrefix + fieldsList[i].getAttribute('name'), fieldsList[i].value);
        }
        filesFieldsList = <NodeListOf<HTMLInputElement>>getElementsList('.upload__drop-file', this.form);
        for (let i: number = 0; i < filesFieldsList.length; i++) {
            if (filesFieldsList[i].files[0] != null) {
                formData.append(filesFieldsList[i].getAttribute('name'), filesFieldsList[i].files[0]);
            }
        }

        let verification_token = getElement('input[name="__RequestVerificationToken"]');
        formData.append(verification_token.getAttribute('name'), verification_token.getAttribute('value'));
        return formData;
    }

    /**
     * Validate all fields, change value of property hasError
     * @method checkErrors
     * @private
     */
    private checkErrors(): void {
        let isBoxItem: Function = (el: Element) => hasClassName('popover__box-item', el);
        this.hasError = false;
        this.validatorsMap.forEach((validator: Validator, selector: string) => {
            let fieldsList: NodeListOf<HTMLInputElement>;
            fieldsList = <NodeListOf<HTMLInputElement>>getElementsList(selector, this.form);
            for (let i: number = 0; i < fieldsList.length; i++) {
                removeClassName('input--error', closest(fieldsList[i], isBoxItem));
                if (!validator.validate(fieldsList[i])) {
                    addClassName('input--error', closest(fieldsList[i], isBoxItem));
                    this.hasError = true;
                }
            }
        });
    }

    /**
     * Bind all event handlers
     * @method bindFormEventHandlers
     * @private
     */
    private bindFormEventHandlers(): void {
        eventOn(this.form, 'submit', (e: Event) => {
            e.preventDefault();
            this.submit();
        });
        let uploadElements: NodeListOf<Element> = getElementsList('.upload', this.form);
        for (let i: number = 0; i < uploadElements.length; i++) {
            FormComponent.bindUploaderEventHandlers(uploadElements[i]);
        }
    }

    /**
     * Add event handlers for files uploading
     * @method bindUploaderEventHandlers
     * @param {Element} el
     * @private
     * @static
     */
    private static bindUploaderEventHandlers(el: Element): void {
        let text: Element = getElement('.upload__drop-text', el);
        let area: Element = getElement('.upload__drop-area', el);
        let file: HTMLInputElement = <HTMLInputElement>getElement('.upload__drop-file', el);
        let buttonCancel: Element = getElement('.upload__drop-button--cancel', el);
        let hold = text.innerHTML;
        eventOn(file, 'change', () => {
            if (typeof file.files[0] === 'undefined') {
                return false;
            }
            addClassName('upload__drop-area--selected', area);
            text.innerHTML = file.files[0].name;
        });
        eventOn(buttonCancel, 'click', () => {
            file.value = '';
            text.innerHTML = hold;
            removeClassName('upload__drop-area--selected', area);
        });
        eventOn(document, 'dragover', () => addClassName('upload__drop-area--over', area));
        eventOn(document, 'dragleave', () => removeClassName('upload__drop-area--over', area));
        eventOn(document, 'drop', () => removeClassName('upload__drop-area--over', area));
    }

}
