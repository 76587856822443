import {
    getElement,
    eventOn
} from './DomUtils';

/**
 * @class TabComponent
 * @public
 */
export default class TabComponent {
    private tabs: Array<Element>;
    private tabLinks: Array<Element>;
    private activeTabClass: string;
    private hiddenTabClass: string;
    private activeTabIndex: number; 
    /**
     * @constructor
     * @param {Element} screenElement
     * @public
     */
    public constructor(tabLinks: string[], tabContent: string[], activeTabClass: string, hiddenTabClass: string) {
        this.tabs = new Array<Element>();
        this.tabLinks = new Array<Element>();
        for (let i=0; i< tabLinks.length; i++) {
            let linkElement = getElement('#'+tabLinks[i]);
            if (linkElement != null) {
                this.tabLinks.push(linkElement);
                if (linkElement.classList.contains(activeTabClass)) {
                    this.activeTabIndex = i;
                }
            }
        };

        tabContent.forEach(element => {
            let tab = getElement('#'+element);
            if (tab != null) {
                this.tabs.push();
            }
        });
        var maxHeight: number = 0;
        this.tabs.forEach(element=> {
            if (element.clientHeight > maxHeight) {
                maxHeight = element.clientHeight;
            }
        });
        this.tabs.forEach(element=> {
            (element as HTMLElement).style.height = maxHeight + 'px';
        });

        this.activeTabClass = activeTabClass;
        this.hiddenTabClass = hiddenTabClass;
        this.bindEventHandlers();
    }


    /**
     * Change active tab
     * @method switchActiveTab
     * @private
     */
    private switchActiveTab(newActiveTabIndex: number) {
        this.tabLinks[this.activeTabIndex].classList.remove(this.activeTabClass);
        this.tabLinks[newActiveTabIndex].classList.add(this.activeTabClass);
        this.tabs[this.activeTabIndex].classList.add(this.hiddenTabClass);
        this.tabs[newActiveTabIndex].classList.remove(this.hiddenTabClass);
        this.activeTabIndex = newActiveTabIndex;
    }

    /**
     * Bind all event handlers
     * @method bindEventHandlers
     * @private
     */
    private bindEventHandlers(): void {
        this.tabLinks.forEach(element=> {
            eventOn(element, 'click', (e: Event) => {
                e.preventDefault();
                this.switchActiveTab(this.tabLinks.indexOf(element));
            })
        });
    }

}
