/**
 * @class Validator
 * @public
 */
export default class Validator {

    validator: Function;

    /**
     * @constructor
     * @param {string} rule - rule of validation
     * @public
     */
    public constructor(rule: string) {
        this.validator = Validator[rule + 'Validate'];
    }

    /**
     * Method that call function for appropriate rule of validation
     * @method validate
     * @param {HTMLInputElement} input - input whose value should be validated
     * @return {boolean}
     * @public
     */
    public validate(input: HTMLInputElement): boolean {
        return this.validator(input);
    }

    /**
     * Check if a string is an email address
     * @method emailValidate
     * @param {HTMLInputElement} input - input whose value should be validated
     * @return {boolean}
     * @static
     * @private
     */
    private static emailValidate(input: HTMLInputElement): boolean {
        return /\S+@\S+\.\S+/.test(input.value);
    }

    /**
     * Check if a string contains any character
     * @method requiredValidate
     * @param {HTMLInputElement} input - input whose value should be validated
     * @return {boolean}
     * @static
     * @private
     */
    private static requiredValidate(input: HTMLInputElement): boolean {
        return input.value.length > 0;
    }

    /**
     * Check maximum file size
     * @method fileMaxSizeValidate
     * @param {HTMLInputElement} input - input whose value should be validated
     * @return {boolean}
     * @static
     * @private
     */
    private static fileMaxSizeValidate(input: HTMLInputElement): boolean {
        let maxSize: number = ~~input.getAttribute('data-max-size');
        return !maxSize || !input.files || !input.files[0] || input.files[0].size <= maxSize;
    }

    /**
     * Check if checkbox is checked
     * @method checkedValidate
     * @param {HTMLInputElement} input
     * @return {boolean}
     * @static
     * @private
     */
    private static checkedValidate(input: HTMLInputElement): boolean {
        return input.checked;
    }
}
