import {
    getElement,
    eventOn,
    overflow,
    toggleClassName,
    addClassName,
    removeClassName,
    hasClassName,
    getWindowScroll
} from './DomUtils';

/**
 * @class HeaderMenuComponent
 * @public
 */
export default class HeaderMenuComponent {

    private headerMenu: Element;

    /**
     * @constructor
     * @param {Element} headerMenuElement
     * @public
     */
    public constructor(headerMenuElement: Element) {
        this.headerMenu = headerMenuElement;
        this.bindEventHandlers();
        this.validateMenu();
    }

    public setForceFixed() {
        if (!hasClassName('fixed', this.headerMenu)) {
                addClassName('header--fixed', this.headerMenu);
                return;
        }
    }

    public validateMenu() {
        if (!hasClassName('fixed', this.headerMenu)) {
            let windowScrollValue: number = getWindowScroll();
            if (window.innerHeight / 2 <= windowScrollValue) {
                addClassName('header--fixed', this.headerMenu);
                return;
            }
            removeClassName('header--fixed', this.headerMenu);
        }
    }

    public hideMobileMenu(): void {
        if (hasClassName('header--expand', getElement('header'))) {
            toggleClassName('header--expand', this.headerMenu);
            overflow();
        }
    }


    /**
     * Bind all event handlers
     * @method bindEventHandlers
     * @private
     */
    private bindEventHandlers(): void {
        let buttonToggle: Element = getElement('.header__toggle', this.headerMenu);

        eventOn(buttonToggle, 'click', (e: Event) => {
            e.preventDefault();
            toggleClassName('header--expand', this.headerMenu);
            overflow();
        });

        if (!hasClassName('fixed', this.headerMenu))
            eventOn(window, 'scroll', () => {
                let windowScrollValue: number = getWindowScroll();
                if (window.innerHeight / 2 <= windowScrollValue) {
                    addClassName('header--fixed', this.headerMenu);
                    return;
                }
                removeClassName('header--fixed', this.headerMenu);
            });
    }

}
