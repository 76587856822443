import {
    getElement,
    getElementsList,
    hasClassName,
    eventOn,
    closest,
    removeClassName,
    addClassName
} from './DomUtils';


/**
 * @class VacancyComponent
 * @public
 */
export default class VacancyComponent {


    private openCallback: Function;
    private vacancyItemsList: NodeListOf<Element>;

    /**
     * @constructor
     * @param vacancyListElement
     * @public
     */
    public constructor(vacancyListElement: Element) {
        this.vacancyItemsList = getElementsList('.section__vacancy-item', vacancyListElement);
        for (let i: number = 0; i < this.vacancyItemsList.length; i++) {
            this.bindItemEventHandlers(this.vacancyItemsList[i]);
        }
    }

    /**
     * @method setOpenCallback
     * @param openCallback
     * @public
     */
    public setOpenCallback(openCallback: Function): void {
        this.openCallback = openCallback;
    }

    /**
     * @method expand
     * @param {Element} itemElement
     * @private
     */
    private expand(itemElement: Element): void {
        let parent: Element = closest(itemElement, (el: Element) => {
            return hasClassName('section__vacancy-item', el);
        });
        for (let i: number = 0; i < this.vacancyItemsList.length; i++) {
            removeClassName('section__vacancy-item--expand', this.vacancyItemsList[i]);
        }
        addClassName('section__vacancy-item--expand', parent);
    }

    /**
     * @method collapse
     * @param {Element} itemElement
     * @private
     */
    private collapse(itemElement: Element): void {
        let parent: Element = closest(itemElement, (el: Element) => {
            return hasClassName('section__vacancy-item--expand', el);
        });
        removeClassName('section__vacancy-item--expand', parent);
    }

    /**
     * @method bindItemEventHandlers
     * @param {Element} itemElement
     * @private
     */
    private bindItemEventHandlers(itemElement: Element): void {
        let buttonExpand: Element = getElement('.section__vacancy-link', itemElement);
        let buttonCollapse: Element = getElement('.button--close', itemElement);
        let buttonOpen: Element = getElement('.button--more', itemElement);

        if (buttonExpand != null) {
            eventOn(buttonExpand, 'click', (e: Event) => {
                e.preventDefault();
                this.expand(itemElement);
            });
        }
        if (buttonCollapse != null) {
            eventOn(buttonCollapse, 'click', (e: Event) => {
                e.preventDefault();
                this.collapse(itemElement);
            });
        }
        if (buttonOpen != null) {
            eventOn(buttonOpen, 'click', (e: Event) => {
                let url: string = buttonOpen.getAttribute('data-url');
                e.preventDefault();
                this.openCallback && this.openCallback(url);
            });
        }
    }

}
