import {
    eventOn,
    getWindowHeight,
    addClassName
} from './DomUtils';

/**
 * @class TechnologyComponent
 * @public
 */
export default class TechnologyComponent {

    private technology: Element;

    /**
     * @constructor
     * @param {Element} technologyElement
     * @public
     */
    public constructor(technologyElement: Element) {
        this.technology = technologyElement;
        this.bindEventHandlers();
    }

    /**
     * Bind all event handlers
     * @method bindEventHandlers
     * @private
     */
    private bindEventHandlers(): void {
        let parentElement: Element = this.technology.parentElement;
        eventOn(window, 'scroll', () => {
            let offset: ClientRect = parentElement.getBoundingClientRect();
            let height: number = getWindowHeight();
            if (offset.top <= height) {
                addClassName('section__technology--animate', this.technology);
            }
        });
    }

}
