/**
 * @class MapComponent
 * @public
 */
export default class MapComponent {

    private map: object;
    private mapElement: Element;
    private constructors: {Map: { new(container: Element, options: object): object }, Marker: { new(options: object): object }};

    /**
     * @constructor
     * @param {Element} mapElement
     * @public
     */
    public constructor(mapElement: Element) {
        this.mapElement = mapElement;
    }

    /**
     * @method setConstructors
     * @param {object} constructors
     * @public
     */
    public setConstructors(constructors: {Map: { new(container: Element, options: object): object }, Marker: { new(options: object): object }}): void {
        this.constructors = constructors;
    }

    /**
     * @method initMap
     * @param {{ lat: number, lng: number }} centerCoords
     * @public
     */
    public initMap(centerCoords: { lat: number, lng: number }): void {
        this.map = new this.constructors.Map(this.mapElement, {
            center: centerCoords,
            scrollwheel: false,
            zoom: 15,
            styles: [{
                stylers: [{
                    saturation: -100
                }]
            }]
        });
    }

    /**
     * @method initMarker
     * @param {{ lat: number, lng: number }} markerCoords
     * @public
     */
    public initMarker(markerCoords: { lat: number, lng: number }) {
        new this.constructors.Marker({
            position: markerCoords,
            map: this.map
        });
    }

    /**
     * @method initMapWithMarker
     * @param {{lat: number, lng: number}} coords
     * @public
     */
    public initMapWithMarker(coords: { lat: number, lng: number }): void {
        this.initMap(coords);
        this.initMarker(coords);
    }

}
