import FormComponent from './FormComponent';
import {
    document,
    bodyElement,
    getElement,
    getElementsList,
    addClassName,
    removeClassName,
    overflow,
    updateSavedWindowPosition,
    eventOn,
    eventOff
} from './DomUtils';

/**
 * Popover with form submitting of job application
 * @class JobApplicationComponent
 * @public
 */
export default class JobApplicationComponent extends FormComponent {

    private popover: Element;
    private buttonClose: Element;
    private buttonPD: Element;
    private buttonSubmit: Element;
    /**
     * @constructor
     * @param {Element} popoverElement
     * @public
     */
    public constructor(popoverElement: Element) {
        super(popoverElement);
        this.popover = popoverElement;
        this.buttonClose = getElement('.button--close', popoverElement);
        this.buttonPD = getElement('#personal-policy', popoverElement);
        this.buttonSubmit = getElement('#submit-button', popoverElement);
        this.bindEventHandlers();
        //this.fieldPrefix = 'Application.';
        this.fieldPrefix = '';
    }

    /**
     * Open popover
     * @method open
     * @public
     */
    public open(url: string): void {
        this.url = url;

        let inputs = getElementsList('.input__field', this.popover);

        for (var i = 0; i < inputs.length; i++) {
            (inputs[i] as HTMLInputElement).value = '';
        }
        (getElement('.upload__drop-file', this.popover) as HTMLInputElement).value = '';
        updateSavedWindowPosition();
        addClassName('body--overflow', bodyElement);
        addClassName('popover--visible', this.popover);
    }

    /**
     * Close popover
     * @method close
     * @public
     */
    public close(): void {
        removeClassName('popover--visible', this.popover);
        removeClassName('popover--process', this.popover);
        removeClassName('popover--success', this.popover);
        removeClassName('popover--fail', this.popover);
        overflow();
    }

    /**
     * Job application form submit handler
     * @method submit
     * @protected
     * @override
     */
    protected submit() {
        super.submit();
        if (this.hasError) {
            return;
        }
        addClassName('popover--process', this.popover);
    }

    /**
     * Job application form submitted handler
     * @method submitted
     * @param {Event} e
     * @protected
     * @override
     */
    protected submitted(e: Event) {
        let self: JobApplicationComponent = this;
        super.submitted(e);
        let xhr: XMLHttpRequest = <XMLHttpRequest>e.target;
        removeClassName('popover--process', this.popover);
        addClassName('popover--' + (xhr.status === 200 ? 'success' : 'fail'), this.popover);
        function closeHandler() {
            self.close();
            eventOff(bodyElement, 'click', closeHandler);
        }
        eventOn(bodyElement, 'click', closeHandler);
    }

    protected enableSubmit() {
        let cb = ((this.buttonPD as HTMLElement) as HTMLInputElement);
        if (cb.checked)
            this.buttonSubmit.removeAttribute('disabled');
        else
            (this.buttonSubmit as HTMLButtonElement).setAttribute('disabled', "");
    }

    /**
     * Bind all event handlers
     * @method bindEventHandlers
     * @private
     */
    private bindEventHandlers(): void {
        
        eventOn(this.buttonClose, 'click', () => this.close());
        eventOn(this.buttonPD, 'click', () => this.enableSubmit());
        
        eventOn(document, 'keyup', (e: KeyboardEvent) => {
         if (e.keyCode === 27)
            return this.close();
         });
    }

}
