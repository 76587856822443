declare global {
    interface Window {
        google: any;
        officeMapCoordinates: { lat: number, lng: number };
    }
}

const baguetteBox = require('baguettebox.js');
import './scss/app.scss';
import {getElement, eventOn, document, documentElement, getElementsList} from './app/DomUtils';
import PageNavigation from './app/PageNavigation';
import JobApplicationComponent from './app/JobApplicationComponent';
import VacancyComponent from './app/VacancyComponent';
import ChartComponent from './app/ChartComponent';
import OfficesMapComponent from './app/OfficesMapComponent';
import HeaderMenuComponent from './app/HeaderMenuComponent';
import MapComponent from './app/MapComponent';
import ScreenComponent from './app/ScreenComponent';
import TechnologyComponent from './app/TechnologyComponent';
import TabComponent from './app/TabComponent';
import CountryValidator from './app/CountryValidator';
/**
 * @function factory
 * @param {Function} component
 * @param {string} selector
 * @return {T}
 */
function factory<T>(component: { new (container: Element): T }, selector: string): T {
    let container: Element = getElement(selector);
    return container ? new component(container) : null;
}

/**
 * @function bindJobApplication
 * @param {JobApplicationComponent} JobApplication
 * @param {VacancyComponent} Vacancy
 */
function bindJobApplication(JobApplication: JobApplicationComponent, Vacancy: VacancyComponent): void {
    let buttons: NodeListOf<Element> = getElementsList('[data-js*="jobApplication"]');
    if (JobApplication) {
        if (buttons) {
            buttons.forEach((buttonOpenJobApplication) => {
                eventOn(buttonOpenJobApplication, 'click', (e: Event) => {
                    e.preventDefault();
                    let url: string = buttonOpenJobApplication.getAttribute('data-url');
                    JobApplication.open(url);
                });
            });
        }
        if (Vacancy) {
            Vacancy.setOpenCallback((url: string) => JobApplication.open(url));
        }
    }
}

/**
 * @function setupViewport
 */
function setupViewport(): void {
    let viewport: HTMLMetaElement = <HTMLMetaElement>getElement('#viewport');
    function changeViewport(): void {
        if (window.screen.width >= 480) {
            viewport.content = 'width=device-width, initial-scale=1.0';
            return;
        }
        viewport.content = 'width=480, shrink-to-fit=no';
    }
    if (viewport) {
        window.onresize = changeViewport;
        changeViewport();
    }
}

function removeProhibited(toRemove: Array<Element>) {
    toRemove.forEach(element => {
        element.parentElement.removeChild(element);
    });
}


function fixVisibility(toShow: Array<Element>) {
    toShow.forEach(element=> {
        element.classList.remove('hidden');
    });
}

function fixSocialNetworks(code: string) {
    var elements = getElementsList('[data-cvl]');
    var toRemove : Array<Element> = [];
    var toShow : Array<Element> = [];

    for (let i=0; i< elements.length; i++) {
            let element = elements[i];
            let option = element.getAttribute('data-cvl');
            (new CountryValidator(option)).isEligible(code) ? toShow.push(element) : toRemove.push(element);
        
    }

    removeProhibited(toRemove);
    fixVisibility(toShow);
}


function CheckCountry(): void {
    let body = getElement('body');
    let codeFromBody = body.hasAttribute('data-cc') ? body.getAttribute('data-cc') : "W";

    if (codeFromBody == 'W') {
        let xhr: XMLHttpRequest = new XMLHttpRequest();
        xhr.open('GET', '/home/GetCountry', true);
        xhr.send();
        Promise.all([
            new Promise((resolve: Function) => setTimeout(resolve, 1400)),
            new Promise((resolve: Function) => eventOn(xhr, 'load', (e: Event) => resolve(e)))
        ]).then((results: any) => fixSocialNetworks(((<Event>results[1]).target as XMLHttpRequest).responseText));
    }
    else {
        fixSocialNetworks(codeFromBody);
    }
}



/**
 * @function run
 */
function run(): void {

    let AddressMap: MapComponent = factory(MapComponent, '[data-js*="address"]');
    let JobApplication: JobApplicationComponent = factory(JobApplicationComponent, '[data-js*="popover"]');
    let Vacancy: VacancyComponent = factory(VacancyComponent, '[data-js*="vacancy"]');
    //let Screen: ScreenComponent = factory(ScreenComponent, '[data-js*="screen"]');
    let tabs: TabComponent = new TabComponent(['in--office--link', 'remote--link'], ['workinoffice','remotework'], 'switch--active', 'hidden');

    bindJobApplication(JobApplication, Vacancy);

    factory(ChartComponent, '[data-js*="plot"]');
    
    let menuComponent = factory(HeaderMenuComponent, '[data-js*="menu"]');
    factory(TechnologyComponent, '[data-js="technology"]');

    /*
    factory(OfficesMapComponent, '[data-js*="officesMap"]');
    if (AddressMap && window.google && window.google.maps && window.google.maps.Map && window.google.maps.Marker) {
        AddressMap.setConstructors(window.google.maps);
        AddressMap.initMapWithMarker(window.officeMapCoordinates);
    }
    */

   //if (Screen) {
    //    Screen.setScrollHandler(PageNavigation.scrollTo);
    //}

    setupViewport();

    let navigation = new PageNavigation(menuComponent);
    baguetteBox.run('.section__gallery-list');
    let hash = document.location.hash;
    if (hash !== null && hash.length > 0 && hash[0] === '#') {
        menuComponent.validateMenu();
        navigation.scrollToElementBySelector(hash);
        setTimeout(()=>{
            
        }, 500);
        
    }
    
    
    CheckCountry();
    
}

/**
 * @function runMap
 */
/*
function runMap(): void {

    let AddressMap: MapComponent = factory(MapComponent, '[data-js*="address"]');

    factory(OfficesMapComponent, '[data-js*="officesMap"]');

    if (AddressMap && window.google && window.google.maps && window.google.maps.Map && window.google.maps.Marker) {
        AddressMap.setConstructors(window.google.maps);
        AddressMap.initMapWithMarker(window.officeMapCoordinates);
    }
}
*/

//eventOn(document, 'DOMContentLoaded', run);
eventOn(window, 'load', run);
//run();
