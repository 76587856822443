import {
    offset,
    getWindowScroll,
    windowScrollTo,
    getElement,
    getElementsList,
    eventOn
} from './DomUtils';

import HeaderMenuComponent from './HeaderMenuComponent';

/**
 * @class PageNavigation
 * @public
 */
export default class PageNavigation {
    private menu: HeaderMenuComponent
    /**
     * @constructor
     * @public
     */
    public constructor(menu: HeaderMenuComponent) {
        this.menu = menu;
        let anchros: NodeListOf<Element> = getElementsList('[data-anchor]');
        for (let i: number = 0; i < anchros.length; i++) {
            this.bindAnchorHandler(anchros[i]);
        }
    }

    /**
     * @method scrollTo
     * @param {number} to
     * @param {number} duration
     * @param {Function} callback
     * @public
     * @static
     */
    public static scrollTo(to: number, duration: number, callback: Function): void {
        let top = getWindowScroll();
        let change = to - top;
        let currentTime = 0;
        let increment = 20;
        PageNavigation.animate(top, change, currentTime, increment, duration, callback);
    }

    /**
     * @method animate
     * @param {number} top
     * @param {number} change
     * @param {number} currentTime
     * @param {number} increment
     * @param {number} duration
     * @param {Function} callback
     * @private
     * @static
     */
    private static animate(top: number, change: number, currentTime: number, increment: number, duration: number, callback: Function): void {
        currentTime += increment;
        let value: number = PageNavigation.easeInOutQuad(currentTime, top, change, duration);
        windowScrollTo(value);
        if (currentTime < duration) {
            setTimeout(() => PageNavigation.animate(top, change, currentTime, increment, duration, callback), increment);
            return;
        }
        callback();
    }

    /**
     * @method easeInOutQuad
     * @param {number} t
     * @param {number} b
     * @param {number} c
     * @param {number} d
     * @return {number}
     * @private
     * @static
     */
    private static easeInOutQuad(t: number, b: number, c: number, d: number): number {
        t /= d / 2;
        if (t < 1)
            return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    }

    public scrollToElementBySelector(selector: string) {
        let target: HTMLElement = getElement(selector) as HTMLElement;
        if (target !== null) {
            this.scrollToElement(target);
        }
    }

    public scrollToElement(target: HTMLElement) {
        let header: HTMLElement = getElement('header') as HTMLElement;        
        let height:number = header.offsetHeight;
        let offsetTop = offset(target).top;
        let scrollTop: number = target.offsetTop - header.offsetHeight;
        if (target !== null) {
            let options: ScrollToOptions = {
                top: scrollTop,
                behavior: 'smooth'
            };
            window.scrollTo(options);
        }
    }

    /**
     * @method bindAnchorHandler
     * @param {Element} anchor
     * @private
     */
    private bindAnchorHandler(anchor: Element): void {
        eventOn(anchor, 'click', (e: Event) => {
            this.menu.hideMobileMenu();
            let targetSelector: string = anchor.getAttribute('data-anchor');
            if (targetSelector !== null) {
                if (getElement(targetSelector) != null) {
                    e.preventDefault();
                    this.menu.setForceFixed();
                    this.scrollToElementBySelector(targetSelector);
                }
            }
        });
    }

}
