import {
    getElement,
} from './DomUtils';

enum ConstraintType {
    OnlyHere,
    NotHere,
    Everywhere
};

/**
 * @class CountryValidator
 * @public
 */
export default class CountryValidator {
    /**
     * @constructor
     * @param {Element} screenElement
     * @public
     */
    public constraintType: ConstraintType;
    public constraintArea: string;
    public constructor(encodedCommand: string) {
        if (encodedCommand.length != 3) {
            this.constraintType = ConstraintType.Everywhere;
        }
        else {
            switch (encodedCommand[0]) {
                case 'O':
                    this.constraintType = ConstraintType.OnlyHere;
                    break;
                case 'N':
                    this.constraintType = ConstraintType.NotHere;
                    break;
                default:
                    this.constraintType = ConstraintType.Everywhere;
            }

            this.constraintArea = encodedCommand.substring(1);
        }

    
    };

    public isEligible(countryCode: string): boolean {
        if (this.constraintArea == countryCode) {
            return this.constraintType != ConstraintType.NotHere;
        }
        else {
            return this.constraintType != ConstraintType.OnlyHere;
        }
    }

}
